import { broadcastMessageToTabs } from './broadcast-message';

function safeGetSessionStorage() {
  try {
    return window?.sessionStorage;
  } catch (_err) {
    // Catch a SecurityError that is thrown if the browser has disabled access
    // to session storage
    return undefined;
  }
}

export function isAvailable() {
  return !!safeGetSessionStorage();
}

export function getItem(key) {
  const storage = safeGetSessionStorage();
  if (!storage) {
    return undefined;
  }

  const string = storage.getItem(key);
  return JSON.parse(string);
}

export function setItem(key, value, { broadcast = false } = {}) {
  const storage = safeGetSessionStorage();
  if (!storage) {
    return;
  }

  const stringToStore = JSON.stringify(value);
  storage.setItem(key, stringToStore);

  if (broadcast) {
    broadcastMessageToTabs('BROADCAST', {
      key,
      data: value,
    });
  }
}

export function removeItem(key) {
  const storage = safeGetSessionStorage();
  if (!storage) {
    return;
  }

  storage.removeItem(key);
}

/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */

class Iframe {
  constructor(id) {
    this.id = id;
    this._iframe = null;
    this._hasLoaded = false;
    this.events = [];
    this._waitingMessages = [];
  }

  style(stylingFn) {
    if (!this._iframe) {
      this._iframe = document.createElement('iframe');
      this._iframe.onload = () => {
        this._hasLoaded = true;
      };
    }
    stylingFn(this._iframe);
  }

  setSrc(url) {
    this._iframe.src = url;
  }

  hasLoaded() {
    return this._hasLoaded;
  }

  mountInto(containerElem, src) {
    if (!this._iframe) {
      this._iframe = document.createElement('iframe');
      this._iframe.onload = () => {
        this._hasLoaded = true;

        this._waitingMessages.forEach((message) => this._sendMessage(message));
        this._waitingMessages = [];
      };
    }
    this._iframe.src = src.url();

    this._startListening();

    containerElem.appendChild(this._iframe);
  }

  attachListener(events) {
    this.events = this.events.concat(events);
  }

  postMessage(type, data) {
    const message = { type, data };

    if (this.hasLoaded()) {
      this._sendMessage(message);
      return;
    }

    this._waitingMessages.push(message);
  }

  _sendMessage(message) {
    this._iframe.contentWindow.postMessage(message, '*');
  }

  _startListening() {
    window.addEventListener('message', (ev) => {
      this._onMessageFromOrigin(ev.origin, ev.data);
    });
  }

  _onMessageFromOrigin(_origin, msgData) {
    const { meta, data } = msgData;
    if (!(meta && data)) {
      return;
    }

    const [placementType, instanceId] = this.id.split('_');
    if (
      meta.placementType !== placementType ||
      meta.instanceId !== instanceId
    ) {
      return;
    }

    const events = this.events.find(([t]) => t === meta.type);
    if (!events) {
      return;
    }
    events[1](data);
  }
}

export default Iframe;

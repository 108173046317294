import { fetchJSON } from '@purple-dot/libraries/src/fetch';
import { createURL } from '@purple-dot/libraries/src/url';
import ApiController from './api-controller';
/* eslint-disable class-methods-use-this */
/* eslint-disable no-useless-constructor */
import debug from './debug';
import createEmbeddedSdk from './embedded/embedded-sdk';
import EventsIntegrations from './events-integrations';
import * as ParentPageSessionToken from './parent-page-session-token';
import SubscriptionController from './subscription-controller';
import TrackingController from './tracking-controller';

function PurpleDotSDK({ hostURL, publicEvents }) {
  const apiController = ApiController();
  const trackingController = TrackingController();

  let apiKey;
  let embeddedSDK;
  let shopifyCart;
  let shopifyCartSignature;
  let shopifyCartString;
  let discountCode;
  let currency;
  let country;
  let locale;
  let enableCart = false;

  const loadQueue = [];
  const updateQueue = [];
  const matchCartButtonToElementQueue = [];

  let customerData = Promise.resolve(null);
  const subscriptionsController = SubscriptionController({
    callbackWrapper: (e) => e.detail,
  });

  function init(opts) {
    if (opts?.apiKey) {
      apiKey = opts.apiKey;
      shopifyCart = opts.shopifyCart;
      shopifyCartSignature = opts.shopifyCartSignature;
      shopifyCartString = opts.shopifyCartString;
      discountCode = opts.discountCode;
      currency = opts.currency;
      country = opts.country;
      locale = opts.locale;
      enableCart = opts.enableCart;

      embeddedSDK = createEmbeddedSdk(
        hostURL,
        publicEvents,
        getCustomerData,
        trackingController,
        opts
      );

      apiController.configure({ hostURL, apiKey });
      trackingController.configure({ apiKey });

      const queryParams = new URLSearchParams(window.location.search);
      const val = queryParams.get('PD_DEBUG');
      // eslint-disable-next-line no-underscore-dangle
      window.__PD_DEBUG = val === 'true';

      // Init can be called from the head
      // which means we need the DOM to be ready
      if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', () => {
          onDomReady();
        });
      } else {
        onDomReady();
      }
    }
  }

  function onDomReady() {
    embeddedSDK.init({
      shopifyCart,
      shopifyCartString,
      shopifyCartSignature,
      discountCode,
      locale,
    });

    loadQueue.forEach((item) => {
      load(item);
    });

    updateQueue.forEach((item) => {
      update(item);
    });

    matchCartButtonToElementQueue.forEach((item) => {
      embeddedSDK.matchCartButtonToElement(item);
    });
  }

  /**
   * Configure and load a Purple Dot placement. Requires an element with
   * data-purple-dot-placement-id to be present somewhere on the page.
   * @param {Object} options
   * @param {string} options.sku - The SKU of the product being used.
   */
  function load({ placementType, instanceId, ...options }) {
    debug('sdk', 'load() ', {
      placementType,
      instanceId,
      ...options,
    });

    if (document.readyState === 'loading') {
      loadQueue.push({
        placementType,
        instanceId,
        currency,
        country,
        ...options,
      });
      return;
    }

    embeddedSDK.load({
      placementType,
      instanceId,
      currency,
      country,
      discountCode,
      ...options,
    });
  }

  /**
   * Update an already configured Purple Dot placement, e.g. to change the SKU.
   * @param {Object} options
   * @param {string} options.sku - The SKU of the product being used.
   */
  function update({ placementType, instanceId = '1', ...options }) {
    debug('sdk', 'update() ', {
      placementType,
      instanceId,
      currency,
      ...options,
    });

    if (document.readyState === 'loading') {
      updateQueue.push({
        placementType,
        instanceId,
        currency,
        country,
        ...options,
      });
      return;
    }

    embeddedSDK.update({
      placementType,
      instanceId,
      currency,
      country,
      ...options,
    });
  }

  /**
   * Subscribe to a Purple Dot event.
   * @param {string} eventName - The name of the event.
   * @param {function} callback - Callback to invoke when the event fires.
   */
  function on(eventName, callback) {
    subscriptionsController.on(eventName, callback);
  }

  /**
   * Unsubscribe from a Purple Dot event.
   * @param {string} eventName - The name of the event.
   * @param {function} callback - Callback to remove
   */
  function off(eventName, callback) {
    subscriptionsController.off(eventName, callback);
  }

  function setCustomerData(data) {
    debug('prefill', 'setCustomerData', data);
    // We accept a function, async function or an object here.
    if (typeof data === 'function') {
      customerData = Promise.resolve(data());
    } else {
      customerData = Promise.resolve(data);
    }
  }

  async function getCustomerData() {
    const data = await customerData;
    debug('prefill', 'getCustomerData', data);

    return (
      data && {
        email: data.email,
        consentedToEmailMarketing: data.consentedToEmailMarketing,
        consentedToSmsMarketing: data.consentedToSmsMarketing,
        address: data.address
          ? {
              firstName: data.address.firstName,
              lastName: data.address.lastName,
              addressLine1: data.address.line1,
              addressLine2: data.address.line2,
              city: data.address.city,
              country: data.address.country,
              postalCode: data.address.postalCode,
              phoneNumber: data.address.phoneNumber,
            }
          : null,
      }
    );
  }

  function getApiKey() {
    return apiKey;
  }

  function navigateTo(url) {
    // This exists mainly so we can stub it in Cypress tests
    window.location = url;
  }

  async function getCheckoutInfo() {
    const url = createURL({
      host: hostURL,
      path: '/embedded-checkout/checkout-info',
      queryParams: {
        parentPageSessionToken: ParentPageSessionToken.getOrCreate(),
        apiKey,
      },
    });

    return await fetchJSON(url);
  }

  return {
    init,
    load,
    loadPreorderStatusPage: (...args) =>
      embeddedSDK.loadPreorderStatusPage(...args),
    loadCollectionPanel: () => undefined,
    update,
    on,
    off,
    setCustomerData,
    getCustomerData,
    getApiKey,
    navigateTo,
    fetchAvailability: (args) => apiController.fetchAvailability(args),
    fetchWaitlists: (args) => apiController.fetchWaitlists(args),
    matchCartButtonToElement: (args) => {
      if (document.readyState === 'loading') {
        matchCartButtonToElementQueue.push(args);
        return;
      }
      embeddedSDK.matchCartButtonToElement(args);
    },
    cartContainsPreorderItems: (...args) =>
      embeddedSDK.cartContainsPreorderItems(...args),
    showCombinedCart: (...args) => embeddedSDK.showCombinedCart(...args),
    track: {
      raw: (...args) => trackingController.track(...args),
      productViewed: (args) => trackingController.productViewed(args),
      skuSelected: (args) => trackingController.skuSelected(args),
      buttonImpression: (args) => trackingController.buttonImpression(args),
    },
    numberOfPreorderItemsInBag: async () => {
      if (!shopperHasSession()) {
        return 0;
      }

      const { numberOfPreorderItemsInBag } = await getCheckoutInfo();

      return numberOfPreorderItemsInBag;
    },
    openCheckout: async () => {
      if (!shopperHasSession()) {
        // biome-ignore lint/suspicious/noConsole: <explanation>
        console.error('Cannot open checkout for shopper, no session found!');
        return;
      }

      const { checkoutId } = await getCheckoutInfo();

      embeddedSDK.openCheckout({ checkoutId, cartEnabled: enableCart });
    },
    events: EventsIntegrations(on),
  };
}

function shopperHasSession() {
  return !!ParentPageSessionToken.get();
}

export default PurpleDotSDK;

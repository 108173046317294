import { mapObj } from '@purple-dot/libraries/src/language-helpers/objects';
import { tapErr } from '@purple-dot/libraries/src/language-helpers/promises';
import setUpInternalTracking from './internal-events';
import publicEvents from './public-events';
import PurpleDotSDK from './sdk';

import trackEvent from './track-event';

function trackAndThrow(err) {
  if (!err.statusCode || err.statusCode < 500) {
    trackEvent('sdk_error', { error: err.toString() });
  }

  throw err;
}

function wrapWithErrTrack(obj) {
  return mapObj((name, attr) => {
    if (typeof attr === 'function') {
      const wrappedFunction = tapErr(trackAndThrow, attr);
      return [name, wrappedFunction];
    }

    if (typeof attr === 'object') {
      return [name, wrapWithErrTrack(attr)];
    }

    return [name, attr];
  }, obj);
}

try {
  const HOST_URL = 'https://www.purpledotprice.com';

  const purpleDot = wrapWithErrTrack(
    PurpleDotSDK({ publicEvents, hostURL: HOST_URL })
  );

  window.PurpleDot = purpleDot;

  setUpInternalTracking(purpleDot);
  publicEvents.ready();
} catch (err) {
  trackAndThrow(err);
}

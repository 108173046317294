import trackEvent from './track-event';

function TrackingController() {
  let apiKey;

  function track(name, params) {
    if (!apiKey) {
      throw new Error('SDK not initialized');
    }

    return trackEvent(name, { ...params, apiKey });
  }

  return {
    track,
    configure: (opts) => {
      apiKey = opts.apiKey;
    },
    productViewed: async ({ productId, available, skus }) => {
      if (!productId) {
        throw new Error('Missing mandatory param: productId');
      }
      if (available === undefined) {
        throw new Error('Missing mandatory param: available');
      }
      if (!(skus && Array.isArray(skus))) {
        throw new Error('Missing mandatory param: skus');
      }

      skus.forEach((sku, i) => {
        if (!sku.skuId) {
          throw new Error(`Missing mandatory param: skus.${i}.skuId`);
        }
        if (sku.available === undefined) {
          throw new Error(`Missing mandatory param: skus.${i}.available`);
        }
      });

      return track('integration.product_viewed', {
        product_external_id: productId,
        available,
        skus: skus.map(({ skuId, available: skuAvailable, stockQuantity }) => ({
          sku_external_id: skuId,
          available: skuAvailable,
          stock_quantity: stockQuantity,
        })),
      });
    },
    skuSelected: ({ skuId, available, stockQuantity, state, releaseId }) => {
      if (!skuId) {
        throw new Error('Missing mandatory param: skuId');
      }
      if (available === undefined) {
        throw new Error('Missing mandatory param: available');
      }

      return track('integration.sku_selected', {
        sku_external_id: skuId,
        available,
        stock_quantity: stockQuantity,
        state,
        release_id: releaseId,
      });
    },
    buttonImpression: async ({
      skuId,
      storeId,
      productId,
      presaleId,
      releaseId,
      buttonState,
      productExternalId,
      skuExternalId,
    }) =>
      track('button_impression', {
        apiKey,
        storeId,
        productId,
        product_external_id: productExternalId,
        sku_external_id: skuExternalId,
        skuId,
        presaleId,
        releaseId,
        buttonState,
      }),
  };
}

export default TrackingController;

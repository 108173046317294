export const ShopifyRoutesAdapter = {
  checkoutRoute: () => {
    const astridHosts = [
      'www.astridandmiyu.com',
      'us.astridandmiyu.com',
      'eu.astridandmiyu.com',
      'astridandmiyudev20.myshopify.com',
    ];

    if (astridHosts.includes(window.location.hostname)) {
      return '/cart';
    }

    return '/checkout';
  },
  homePageRoute: () => (window as any).Shopify?.routes?.root || '/',
};

import trackEvent from './track-event';

function setUpInternalTracking(purpleDot) {
  purpleDot.on('Ready', () => {
    trackEvent('sdk_loaded');
  });

  function track(name, params) {
    const apiKey = purpleDot.getApiKey();
    trackEvent(name, { ...params, apiKey });
  }

  purpleDot.on(
    'PlacementLoaded',
    ({ placementType, skuId, displayCurrency, disabled, releaseId }) => {
      track('placement_loaded', {
        placementType,
        skuId,
        displayCurrency,
        disabled,
        releaseId,
      });
    }
  );

  purpleDot.on('PlacementFailed', (data) => {
    track('placement_failed', data);
  });

  purpleDot.on('LearnMoreClicked', ({ skuId, displayCurrency }) => {
    track('learn_more_clicked', { skuId, displayCurrency });
  });

  purpleDot.on('ButtonClicked', ({ skuId, displayCurrency, releaseId }) => {
    track('button_clicked', {
      skuId,
      displayCurrency,
      releaseId,
    });
  });

  // Pre-orders
  purpleDot.on(
    'PreorderCheckoutStep',
    ({ stepNumber, stepName, skuId, releaseId }) => {
      track('pre_order_checkout_step', {
        stepNumber,
        stepName,
        skuId,
        releaseId,
      });
    }
  );

  purpleDot.on('PreorderCheckoutSubmitted', ({ skuId, releaseId }) => {
    track('pre_order_checkout_submitted', { skuId, releaseId });
  });

  purpleDot.on(
    'PreorderCreated',
    ({ reference, skuInternalId, lineItems, total }) => {
      track('pre_order_created', {
        reference,
        skuId: skuInternalId,
        lineItems,
        total,
      });
    }
  );

  purpleDot.on(
    'OrderCreated',
    ({ reference, skuInternalId, lineItems, total }) => {
      track('order_created', {
        reference,
        skuId: skuInternalId,
        lineItems,
        total,
      });
    }
  );

  purpleDot.on('CheckoutLoaded', ({ enableCombinedCart = false }) => {
    track('checkout_loaded', {
      enableCombinedCart,
    });
  });

  purpleDot.on('PreorderFailed', ({ errorMessage, skuId }) => {
    track('pre_order_failed', { errorMessage, skuId });
  });

  purpleDot.on('AddToCart', ({ internalSkuId, releaseId }) => {
    track('add_to_cart', { skuId: internalSkuId, releaseId });
  });
}

export default setUpInternalTracking;

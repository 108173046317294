function ComputedAttribute(element, attribute) {
  return {
    onChange: (cb, { pollingInterval = 250 } = {}) => {
      let previousValue;

      setInterval(() => {
        const style = window.getComputedStyle(element);
        const newValue = style[attribute];

        if (newValue !== previousValue) {
          cb(newValue);
          previousValue = newValue;
        }
      }, pollingInterval);
    },
  };
}

export default ComputedAttribute;

export async function fetchJSON(path: string, { throwOnError = true } = {}) {
  const response = await global.fetch(path, {
    headers: new Headers({
      Accept: 'application/json',
    }),
    credentials: 'same-origin',
  });
  return unwrapJSONResponse(response, throwOnError);
}

export async function postJSON(
  path: string,
  body: object,
  {
    throwOnError = true,
    headers = {},
    credentials = 'same-origin',
  }: {
    throwOnError?: boolean;
    headers?: HeadersInit;
    credentials?: RequestCredentials;
  } = {}
) {
  const response = await global.fetch(path, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    }),
    credentials,
  });
  return unwrapJSONResponse(response, throwOnError);
}

export async function deleteJSON(path: string, { throwOnError = true } = {}) {
  const response = await global.fetch(path, {
    method: 'DELETE',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    credentials: 'same-origin',
  });
  return unwrapJSONResponse(response, throwOnError);
}

async function unwrapJSONResponse(response: Response, throwOnError: boolean) {
  if (response.ok || !throwOnError) {
    return response.json();
  }

  let errorMessage = `HTTP Status Error: ${response.status}`;
  let originalMessage;
  let errData;
  try {
    const res = await response.json();
    const message = res.error?.message || res.meta?.error;
    if (message) {
      errorMessage = `HTTP Status Error: ${response.status} - ${message}`;
      originalMessage = message;
    }

    const data = res.error?.data || res.meta?.errorData;
    if (data) {
      errData = data;
    }
  } catch (err) {
    // biome-ignore lint/suspicious/noConsole: log
    console.error(err);
  }

  const err = new Error(errorMessage);
  // Mark this error as having the response data to work with
  // As opposed to a network error
  (err as any).responseSucceeded = true;
  (err as any).statusCode = response.status;
  (err as any).originalMessage = originalMessage;
  (err as any).data = errData;
  throw err;
}

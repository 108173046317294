function isIframeSameOrigin(iframe) {
  try {
    const srcOrigin = new URL(iframe.src).origin;
    return srcOrigin !== iframe.contentWindow.origin;
  } catch {
    return false;
  }
}

export default isIframeSameOrigin;

export function tapErr(
  onErr: (err: any) => void,
  func: (...args: any[]) => any
) {
  return (...args: any[]) => {
    try {
      const result = func(...args);
      if (!result) {
        return result;
      }

      if (result.then) {
        return result.catch((err: any) => {
          onErr(err);
          throw err;
        });
      }

      return result;
    } catch (err) {
      onErr(err);
      throw err;
    }
  };
}

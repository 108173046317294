const safeGetLocalStorage = () => {
  try {
    return window?.localStorage;
  } catch (_err) {
    // Catch a SecurityError that is thrown if the browser has disabled access
    // to local storage
    return undefined;
  }
};

export const setListener = (cb: (e: StorageEvent) => void) => {
  const storage = safeGetLocalStorage();
  if (!storage) {
    return;
  }

  window.addEventListener('storage', (e) => {
    cb(e);
  });
};

// biome-ignore lint/suspicious/noExplicitAny: Allow saving anything in local storage
export const setItem = (key: string, value: any) => {
  const storage = safeGetLocalStorage();
  if (!storage) {
    return;
  }

  const stringToStore = JSON.stringify(value);
  storage.setItem(key, stringToStore);
};

export const getItem = (key: string) => {
  const storage = safeGetLocalStorage();
  if (!storage) {
    return undefined;
  }

  const string = storage.getItem(key);
  return string ? JSON.parse(string) : string;
};

export const removeItem = (key: string) => {
  const storage = safeGetLocalStorage();
  if (!storage) {
    return;
  }

  storage.removeItem(key);
};

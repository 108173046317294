// biome-ignore lint/style/useNodejsImportProtocol: need webpack to resolve this import
// biome-ignore lint/style/useNodejsImportProtocol: need webpack to resolve this import
import { parse } from 'url';
import { isNil } from 'ramda';
import { checkParam } from '../language-helpers/objects';

export function createURL({
  host,
  path,
  queryParams,
  hash,
}: {
  host: string;
  path: string;
  queryParams: Record<string, any>;
  hash?: string;
}) {
  const url = new URL(`${host}${path}`);

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value === undefined || value === null) {
      return;
    }

    if (typeof value === 'object') {
      url.searchParams.set(key, JSON.stringify(value));
    } else {
      url.searchParams.set(key, value);
    }
  });

  if (hash) {
    url.hash = hash;
  }

  return url.toString();
}

export function appendQuery(path: string, query: Record<string, string>) {
  if (isNil(query)) {
    return path;
  }

  const url = new URL(path, 'https://example.com');

  for (const [name, value] of Object.entries(query)) {
    if (!isNil(value)) {
      url.searchParams.append(name, value);
    }
  }

  return url.pathname + url.search;
}

export function appendResourceType(type: string, url: string) {
  checkParam({ type });
  checkParam({ url });

  const parsed = parse(url);

  const path = buildPath(type, parsed);

  if (!parsed.hostname) {
    return path;
  }

  return `${parsed.protocol}//${parsed.host}${path}`;
}

export function buildPath(
  type: string,
  { pathname, search }: { pathname: string | null; search: string | null }
) {
  if (search) {
    return `${pathname}.${type}${search}`;
  }
  return `${pathname}.${type}`;
}

const DOMAIN_REGEX = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;
export function isDomainValid(domain?: string | null) {
  return !!domain?.match(DOMAIN_REGEX);
}

import { BadRequestError } from '../../errors/bad-request-error';
import { sleep } from './sleep';

/**
 * pollAsync
 *
 * fn: () -> Promise<PollResult> { stopPolling: bool, result: any }
 * delay: (polledTimes: int) -> millisecondsToSleep: int
 * timeout: int
 *
 * pollAsync is current used in OnSuccessfulPayment
 * while poll is used in frontend checkout polling.
 * Due to this I don't want to merge this atm for risk
 * of a subtle breakage. So instead this is being left here
 * as the latest version to use
 */
class PollingTimeoutError extends Error {
  data: object;

  constructor(message: string, data: object = {}) {
    super(message);
    this.name = this.constructor.name;
    this.data = data;
    Error.captureStackTrace(this, this.constructor);
  }
}

export const Errors = {
  PollingTimeoutError,
};

export async function pollAsync<T>(
  fn: (timeSpent: number) => Promise<{ stopPolling: boolean; result: T }>,
  delay: (polledTimes: number) => number,
  timeout: number
) {
  if (!fn || typeof fn !== 'function') {
    throw new BadRequestError('fn is required for pollAsync');
  }

  if (!delay || typeof delay !== 'function') {
    throw new BadRequestError('delay is required for pollAsync');
  }

  if (!timeout || typeof timeout !== 'number') {
    throw new BadRequestError('number is required for pollAsync');
  }

  const startTime = Date.now();

  async function p(polledTimes: number): Promise<T> {
    const timeSpent = Date.now() - startTime;
    if (timeSpent >= timeout) {
      throw new PollingTimeoutError('Timeout');
    }

    const pollResult = (await fn(timeSpent)) || {};

    if (pollResult.stopPolling) {
      return pollResult.result;
    }

    await sleep(delay(polledTimes));

    return await p(polledTimes + 1);
  }

  return await p(0);
}

const preferredLocales = {
  en: 'en-US',
  en_US: 'en-US',
  en_GB: 'en-GB',

  es: 'es-MX',
  es_MX: 'es-MX',

  de: 'de-DE',
  de_DE: 'de-DE',

  it: 'it-IT',
  it_IT: 'it-IT',

  fr: 'fr-FR',
  fr_FR: 'fr-FR',

  nl: 'nl-NL',
  nl_NL: 'nl-NL',
};

function localeFromString(str?: string): string {
  if (str) {
    const localeKey = str.toLowerCase();

    for (const [k, v] of Object.entries(preferredLocales)) {
      if (localeKey === v.toLowerCase() || localeKey === k.toLowerCase()) {
        return v;
      }
    }
  }

  return preferredLocales.en_US;
}

function possibleLocales(): string[] {
  if (!window) {
    return ['en'];
  }

  const shopify = window.Shopify;

  let shopifyLocale: string | null = null;

  if (shopify?.locale) {
    if (shopify.locale.includes('-')) {
      shopifyLocale = shopify.locale;
    } else if (shopify.country) {
      shopifyLocale = `${shopify.locale}-${shopify.country}`;
    } else {
      shopifyLocale = shopify.locale;
    }
  }

  const queryParamLocale = new URLSearchParams(window.location.search).get(
    'locale'
  );

  return [
    queryParamLocale,
    window.PurpleDotConfig?.locale,
    shopifyLocale,
    document.documentElement.lang,
    ...navigator.languages,
    'en',
  ].filter((l) => l != null);
}

export function autoDetectLocale() {
  return localeFromString(possibleLocales()[0]);
}

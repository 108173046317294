import { v4 as uuid } from 'uuid';
import * as LocalStorage from './libraries/local-storage';

const TOKEN_NAME = '_pd_parent_page_session_token';

export function setUpNew() {
  const token = uuid();
  LocalStorage.setItem(TOKEN_NAME, token);
  return token;
}

export function getOrCreate() {
  const token = LocalStorage.getItem(TOKEN_NAME);
  if (!token) {
    return setUpNew();
  }
  return token;
}

export function get() {
  return LocalStorage.getItem(TOKEN_NAME);
}

/* eslint-disable no-underscore-dangle */

const ID = 'checkout-state';
const CREATE_TYPE = 'checkout-created';
const ADD_TYPE = 'line-item-added';

export class ParentPageSDKCheckoutUpdates {
  constructor({ checkout, cart }, publicEvents, flags) {
    this.checkout = checkout;
    this.cart = cart;
    this.publicEvents = publicEvents;
    this.addItemCartBehaviour = flags.addItemCartBehaviour;
  }

  start() {
    window.addEventListener('message', (ev) => {
      const { meta, data } = ev.data;
      if (!meta) {
        return;
      }

      if (meta.placementType !== ID) {
        return;
      }

      if (meta.type === CREATE_TYPE) {
        this.checkout.open(data);

        this._trackAddToCart(data);
        return;
      }

      if (meta.type === ADD_TYPE) {
        if (this.addItemCartBehaviour === 'open_checkout') {
          this.checkout.open(data);
        } else {
          this.cart.show();
        }

        this._trackAddToCart(data);
      }
    });
  }

  _trackAddToCart(data) {
    const { skuId, releaseId, externalId, price, quantity } = data;
    this.publicEvents.addToCart({
      skuId: externalId,
      internalSkuId: skuId,
      releaseId,
      price,
      quantity: quantity ?? 1,
    });
  }
}

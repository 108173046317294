/* eslint-disable no-useless-constructor */
/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { getPlacementIframes } from '@purple-dot/placement-iframe';

class Cart {
  constructor(hostURL, apiKey, locale) {
    this.hostURL = hostURL;
    this.apiKey = apiKey;
    this.locale = locale;
    this.listeners = [];

    this._startListening();
  }

  mount() {
    if (this.iframe) {
      return;
    }
    this.iframe = this._iframe();
    document.body.appendChild(this.iframe);

    this._closeWhenOutsideClicked();
    this._scrollIfDocScrolled();
  }

  show() {
    if (!this.iframe) {
      return;
    }

    this._updateCartPosition();

    this.iframe.style.display = 'inline-block';
    document.activeElement.blur();

    try {
      this.iframe.contentWindow.focus();

      const ifrms = getPlacementIframes({ placementType: 'button' });
      ifrms.forEach((ifrm) => {
        ifrm.contentWindow.postMessage(
          {
            type: 'CART_SHOWN',
          },
          this.hostURL
        );
      });

      this.iframe.contentWindow.postMessage(
        {
          type: 'CART_SHOWN',
        },
        this.hostURL
      );

      this.iframe.contentWindow.focus();
    } catch (err) {
      const isJsDom = navigator.userAgent.includes('jsdom');
      if (!isJsDom) {
        throw err;
      }
    }
  }

  hide() {
    if (!this.iframe) {
      return;
    }

    this.iframe.style.display = 'none';
    try {
      this.iframe.contentWindow.postMessage(
        {
          type: 'CART_HIDDEN',
        },
        this.hostURL
      );
    } catch (err) {
      const isJsDom = navigator.userAgent.includes('jsdom');
      if (!isJsDom) {
        throw err;
      }
    }
  }

  toggleVisibility() {
    if (this.iframe.style.display === 'none') {
      this.show();
    } else {
      this.hide();
    }
  }

  askForPositionFrom(newPositioner) {
    this.positioner = newPositioner;
  }

  attachListener(listener) {
    if (!listener.cartCheckoutBtnClicked) {
      throw new Error('Could not attach listener');
    }

    this.listeners.push(listener);
  }

  _startListening() {
    window.addEventListener('message', (message) => {
      if (message.origin !== this.hostURL) {
        return;
      }

      if (message.data.meta && message.data.meta.placementType === 'cart') {
        const { width, height } = message.data.data;
        this.iframe.width = width;
        this.iframe.height = height;
        return;
      }

      const { type, ...data } = message.data;
      switch (type) {
        case 'PD_CART_CHECKOUT_BUTTON_CLICKED':
          this.listeners.forEach((listener) =>
            listener.cartCheckoutBtnClicked(data)
          );
          this.hide();
          break;

        case 'PD_CART_CLOSE_BUTTON_CLICKED':
          this.hide();
          break;

        case 'CART_CLEARED':
          this.hide();
          break;

        default:
          break;
      }
    });
  }

  _updateCartPosition() {
    this.positioner.placeBelowCartButton(this.iframe, {
      desktopWidth: '420px',
    });
  }

  _closeWhenOutsideClicked() {
    // When a shopper clicks outside of the cart
    // hide the cart
    document.addEventListener(
      'click',
      () => {
        this.hide();
      },
      { capture: true, passive: true }
    );
  }

  _scrollIfDocScrolled() {
    // When a shopper scrolls
    // make sure the cart scrolls as well
    document.addEventListener(
      'scroll',
      () => {
        this._updateCartPosition();
      },
      { passive: true }
    );
  }

  _iframe() {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('id', 'purple-dot-cart');
    iframe.setAttribute('role', 'dialog');
    iframe.setAttribute('allowtransparency', true);
    iframe.style.position = 'fixed';
    iframe.style['z-index'] = '2147483647';
    iframe.style.border = 'none';
    iframe.style['box-shadow'] = '0 4px 10px rgb(0 0 0 / 15%)';
    iframe.style['border-radius'] = '5px';
    iframe.style.display = 'none';
    iframe.src = `${this.hostURL}/embedded-checkout/cart?apiKey=${this.apiKey}&locale=${this.locale}`;
    return iframe;
  }
}

export default Cart;

import { createURL } from '@purple-dot/libraries/src/url';

function ApiController() {
  let apiKey;
  let hostURL;

  return {
    configure: (opts) => {
      apiKey = opts.apiKey;
      hostURL = opts.hostURL;
    },
    fetchAvailability: ({ productId, variantId }) => {
      if (!(apiKey && hostURL)) {
        throw new Error('SDK not initialized');
      }
      const url = createURL({
        host: hostURL,
        path: '/api/v1/availability',
        queryParams: {
          api_key: apiKey,
          product_id: productId,
          variant_id: variantId,
        },
      });
      return fetch(url).then(unwrapApiResponse);
    },

    fetchWaitlists: () => {
      if (!(apiKey && hostURL)) {
        throw new Error('SDK not initialized');
      }
      const url = createURL({
        host: hostURL,
        path: '/api/v1/waitlists',
        queryParams: {
          api_key: apiKey,
        },
      });
      return fetch(url).then(unwrapApiResponse);
    },
  };
}

async function unwrapApiResponse(res) {
  if (res.ok) {
    const body = await res.json();
    return body.data;
  }

  let errorMessage;
  try {
    const body = await res.json();
    errorMessage = body.meta.error;
  } catch (_err) {
    errorMessage = `HTTP Error ${res.status}`;
  }

  const error = new Error(errorMessage);
  error.statusCode = res.status;
  throw error;
}

export default ApiController;

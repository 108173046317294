import * as ParentPageSessionToken from '../../parent-page-session-token';
import Iframe from '../iframe';
/* eslint-disable no-underscore-dangle */
import Button from './button';
import CartButton from './cart-button';
import PlacementSrc from './placement-src';
import {
  FixedWidthResizeBehaviour,
  IntrinsicSizeResizeBehaviour,
} from './resize-behaviours';
import SimplePlacement from './simple-placement';

class PlacementFactory {
  constructor(
    hostURL,
    apiKey,
    staticComponents,
    flags,
    publicEvents,
    impressionTracker
  ) {
    this.hostURL = hostURL;
    this.apiKey = apiKey;
    this.staticComponents = staticComponents;
    this.publicEvents = publicEvents;
    this.impressionTracker = impressionTracker;
    this.flags = flags;
  }

  button(id, requestData) {
    return new Button(
      new SimplePlacement(
        requestData,
        new Iframe(id),
        new PlacementSrc(this.hostURL, this.apiKey, requestData, {
          parentPageSessionToken: this._getParentPageSessionToken(),
          enableCart: this.flags.enableCartFlag,
          enableNotify: this.flags.enableNotifyFlag,
          enableCombinedCart: this.flags.enableCombinedCart,
          salesChannel: this.flags.salesChannel,
          locale: this.flags.locale,
        }),
        requestData?.style?.width
          ? new FixedWidthResizeBehaviour(requestData.style.width)
          : new IntrinsicSizeResizeBehaviour()
      ),
      this.options,
      this.staticComponents,
      this.publicEvents,
      this.impressionTracker
    );
  }

  cartButton(id, requestData) {
    if (this.flags.enableCartFlag) {
      return new CartButton(
        new SimplePlacement(
          requestData,
          new Iframe(id),
          new PlacementSrc(this.hostURL, this.apiKey, requestData, {
            parentPageSessionToken: this._getParentPageSessionToken(),
            locale: this.flags.locale,
          }),
          new IntrinsicSizeResizeBehaviour()
        ),
        this.staticComponents.cart,
        this.publicEvents
      );
    }

    // Null placement
    return {
      mountInto() {},
      update() {},
    };
  }

  simple(id, requestData) {
    return new SimplePlacement(
      requestData,
      new Iframe(id),
      new PlacementSrc(this.hostURL, this.apiKey, requestData, {
        ...this.options,
        locale: this.flags.locale,
      }),
      requestData?.style?.width
        ? new FixedWidthResizeBehaviour(requestData.style.width)
        : new IntrinsicSizeResizeBehaviour()
    );
  }

  _getParentPageSessionToken() {
    if (this.flags.delaySession) {
      return ParentPageSessionToken.get();
    }
    return ParentPageSessionToken.getOrCreate();
  }
}

export default PlacementFactory;

import debug from './debug';

function buttonImpressionHandler(trackingController, iframe) {
  if (!(trackingController && iframe)) {
    throw new Error('buttonImpressionHandler could not be created');
  }

  let isVisible;

  // Not all browsers support this API so we do a check here
  if (window.IntersectionObserver) {
    // We use an intersection observer to continuously keep track of whether
    // the button is "visible" within the page's body
    const observer = new IntersectionObserver(
      ([entry]) => {
        isVisible = entry.isIntersecting;
        debug('sdk', 'buttonImpressionHandler() - intersection update', entry);
      },
      {
        root: document.querySelector('body'),
        threshold: 1.0,
      }
    );
    observer.observe(iframe);
  }

  let timeout;

  function handleButtonImpression(data, isRetry) {
    debug('sdk', 'handleButtonImpression()', data);

    // Cancel any previously scheduled impressions if a new one comes in, we
    // only want to log the latest one in that case
    clearTimeout(timeout);

    // A simpler visibility check that is a fallback for older browsers
    const isVisibleOlderBrowsers = iframe.offsetParent !== null;
    if (isVisible || isVisibleOlderBrowsers) {
      debug('sdk', 'handleButtonImpression() - is visible - track');
      // If the button is visible when an event comes in, log it straight away
      trackingController.buttonImpression(data);
    } else if (!isRetry) {
      debug('sdk', 'handleButtonImpression() - is not visible - retry');
      // If the button is not visible right now, try again in 100ms - this
      // helps us get around some timing issues where we receive an event
      // just before the button becomes visible
      timeout = setTimeout(() => handleButtonImpression(data, true), 100);
    } else {
      debug('sdk', 'handleButtonImpression() - is not visible - do not retry');
    }
  }

  return handleButtonImpression;
}

export default buttonImpressionHandler;

/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */

import ComputedAttribute from './computed-attribute';

/* eslint-disable no-underscore-dangle */
class CartButton {
  constructor(simplePlacement, cart, publicEvents) {
    this.simplePlacement = simplePlacement;
    this.cart = cart;
    this.publicEvents = publicEvents;

    this.listeners = [];
  }

  mountInto(container) {
    this.simplePlacement.iframe.style((elem) => {
      elem.style.display = 'none';
    });

    this.simplePlacement.iframe.attachListener([
      [
        'loaded',
        (data) => {
          if (data.showButton) {
            this.show();
          } else {
            this.hide();
          }
          this.cart.mount();
        },
      ],
      [
        'cart-button-entered',
        () => {
          this.cart.show();
        },
      ],
      [
        'cart-button-clicked',
        () => {
          this.cart.toggleVisibility();
        },
      ],
      [
        'visibility-changed',
        (data) => {
          if (data.show) {
            this.show();
          } else {
            this.hide();
          }
        },
      ],
    ]);

    this.simplePlacement.mountInto(container);
  }

  update(args) {
    this.simplePlacement.iframe.postMessage('UPDATE', { fill: args.fill });
  }

  show() {
    this.simplePlacement.iframe.style((elem) => {
      elem.style.display = 'inline-block';
    });
    this.publicEvents.cartButtonVisibilityChanged({ showing: true });
  }

  hide() {
    this.simplePlacement.iframe.style((elem) => {
      elem.style.display = 'none';
    });
    this.publicEvents.cartButtonVisibilityChanged({ showing: false });
  }

  isVisible() {
    const elem = this.simplePlacement.iframe._iframe;
    return !!(
      elem.offsetWidth ||
      elem.offsetHeight ||
      elem.getClientRects().length
    );
  }

  inViewport() {
    const elem = this.simplePlacement.iframe._iframe;
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  getBoundingClientRect() {
    const elem = this.simplePlacement.iframe._iframe;
    return elem.getBoundingClientRect();
  }

  matchCartButtonToElement(opts) {
    const element = document.querySelector(opts.element);

    const attribute = ComputedAttribute(element, opts.cssAttribute);

    attribute.onChange((newValue) => {
      this.update({ fill: newValue });
    });
  }

  attachListener(listener) {
    if (!(listener.onEnter && listener.onClicked)) {
      throw new Error('Listener could not be added to CartButton');
    }

    this.listeners.push(listener);
  }
}

export default CartButton;

export class CustomError extends Error {
  constructor(
    message: string,
    public data?: object
  ) {
    super(message);
    this.name = this.constructor.name;

    // Only available in Chrome and Node.js
    if ('captureStackTrace' in Error) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

import { injectOverlayIframe } from '@purple-dot/libraries/src/iframe-connect/overlay-manager';
import { createURL } from '@purple-dot/libraries/src/url';
import trackEvent from '../../track-event';

class LearnMore {
  constructor(hostURL, apiKey, locale) {
    this.hostURL = hostURL;
    this.apiKey = apiKey;
    this.locale = locale;
  }

  open({ productId, releaseId }) {
    injectOverlayIframe({
      hostURL: this.hostURL,
      id: 'purple-dot-learn-more',
      src: this._learnMoreUrl({ productId, releaseId }),
    }).then(() => {
      trackEvent('placement_loaded', { placementType: 'learn-more' });
    });
  }

  _learnMoreUrl({ productId, releaseId }) {
    return createURL({
      host: this.hostURL,
      path: '/embedded-checkout/pre-order-value-prop',
      queryParams: {
        apiKey: this.apiKey,
        productId,
        releaseId,
        locale: this.locale,
      },
    });
  }
}

export default LearnMore;

import isControllerMessage from './is-controller-message';

function messageHandler(messageType, callback) {
  return (event) => {
    if (!isControllerMessage(event)) {
      return;
    }
    const { meta, data } = event.data;
    if (meta.messageType === messageType) {
      callback({ meta, data });
    }
  };
}

export default messageHandler;

function SubscriptionController({ callbackWrapper = (x) => x } = {}) {
  const callbacks = [];

  return {
    on: (eventNames, callback) => {
      function listener(e) {
        callback(callbackWrapper(e));
      }

      ensureArray(eventNames).forEach((eventName) => {
        callbacks.push({ eventName, callback, listener });
        window.addEventListener(`PurpleDot:${eventName}`, listener);
      });
    },
    off: (eventNames, callback) => {
      ensureArray(eventNames).forEach((eventName) => {
        const idx = callbacks.findIndex(
          (saved) =>
            saved.eventName === eventName && saved.callback === callback
        );
        if (idx > -1) {
          window.removeEventListener(
            `PurpleDot:${eventName}`,
            callbacks[idx].listener
          );
          callbacks.splice(idx, 1);
        }
      });
    },
    subscribers: (eventName) =>
      callbacks.filter((saved) => saved.eventName === eventName),
  };
}

function ensureArray(x) {
  if (Array.isArray(x)) {
    return x;
  }
  return [x];
}

export default SubscriptionController;

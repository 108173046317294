import { createURL } from '@purple-dot/libraries/src/url';
import { injectPlacementIframe } from '@purple-dot/placement-iframe';
import { autoDetectLocale } from '@purple-dot/translations/locale';

let HOST_URL = 'https://www.purpledotprice.com';

class SelfServiceWebComponent extends HTMLElement {
  connectedCallback() {
    this.dataset.purpleDotPlacementType = 'pre-order-status-placement';

    void injectPlacementIframe({
      hostURL: HOST_URL,
      src: this.getIframeSrc(),
      style: { width: '100%', height: '100%' },
      placementType: 'pre-order-status-placement',
    });
  }

  getIframeSrc() {
    const mode = this.dataset.mode;
    const apiKey = this.dataset.apiKey;
    const email = this.dataset.email;
    const token = this.dataset.token;
    const preorderId = this.dataset.preorderId;

    if (mode === 'logged_in' && token && apiKey) {
      return createURL({
        host: HOST_URL,
        path: '/embedded-checkout/pre-order-status',
        queryParams: {
          locale: autoDetectLocale(),
          email: email || '',
          token,
          apiKey,
          mode: 'logged_in',
        },
      });
    }

    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    const path =
      preorderId && uuidRegex.test(preorderId)
        ? `/shopper/pre-orders/${preorderId}`
        : '/shopper';

    return createURL({
      host: HOST_URL,
      path,
      queryParams: {
        locale: autoDetectLocale(),
        ...getStoreIdentifier(),
        ...(window.PurpleDotConfig?.apiCredentials || {}),
        embedded: 'true',
      },
    });
  }
}

export function defineSelfServiceCustomElement(hostUrl?: string) {
  if (hostUrl) {
    HOST_URL = hostUrl;
  }

  customElements.define('purple-dot-self-service', SelfServiceWebComponent);
}

export function handleLegacyManagePreordersEl({
  apiKey,
  email,
  token,
}: {
  apiKey?: string;
  email?: string;
  token?: string;
}) {
  const el = document.querySelector(
    '[data-purple-dot-placement-type="pre-order-status-placement"]'
  );

  if (el && el.tagName !== 'PURPLE-DOT-SELF-SERVICE') {
    const webComponentEl = document.createElement('purple-dot-self-service');

    if (apiKey && token) {
      webComponentEl.dataset.apiKey = apiKey;
      webComponentEl.dataset.mode = 'logged_in';
      webComponentEl.dataset.email = email;
      webComponentEl.dataset.token = token;
    }

    el.parentElement?.replaceChild(webComponentEl, el);
  }
}

declare const window: any;

// TODO: Dupe from packages/web-components/learn-more/learn-more-modal.ts
function getStoreIdentifier() {
  if (window.PurpleDotConfig?.apiKey) {
    return { apiKey: window.PurpleDotConfig?.apiKey };
  }

  if (window.Shopify?.shop) {
    return { shop: window.Shopify?.shop };
  }

  return {};
}

import cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import getDeviceId from './device-id';
import * as SessionStorage from './libraries/session-storage';

const HOST_URL = 'https://www.purpledotprice.com';

async function trackEvent(name, attrs = {}) {
  const { deviceId, storage } = getDeviceId();
  const cartToken = cookies.get('cart') ?? null;
  const event = {
    id: uuid(),
    name,
    attrs: {
      deviceIdPersistent: storage === 'cookie',
      deviceIdStorage: storage,
      cartToken,
      ...attrs,
    },
    deviceId,
    clientTimestamp: Date.now(),
    clientSequence: getAndIncrementSequenceNumber(),
    url: window.location.href,
    referrer: window.document.referrer,
  };
  // If HOST_URL placeholder didn't get replaced, assume we are on our own
  // domain and make the URL relative
  const host = HOST_URL.startsWith('__') ? '' : HOST_URL;

  const trackRequest = fetch(`${host}/api/v1/track`, {
    method: 'POST',
    body: JSON.stringify(event),
    mode: 'cors',
    keepalive: true,
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  });

  try {
    await trackRequest;
  } catch (error) {
    if (error instanceof TypeError) {
      // This was some sort of network error. Just ignore it.
    } else {
      throw error;
    }
  }
}

function getAndIncrementSequenceNumber() {
  const key = '_pdts';
  const sequenceNumber = SessionStorage.getItem(key) || 0;
  SessionStorage.setItem(key, sequenceNumber + 1);
  return sequenceNumber;
}

export default trackEvent;

/* eslint-disable max-classes-per-file,class-methods-use-this,no-param-reassign */

class IntrinsicSizeResizeBehaviour {
  // Sets the size based on the intrinsic size of the placement

  setInitialSize() {}

  adjustSize(elem, { width, height }) {
    if (width) {
      elem.style.width = `${width}px`;
    }
    if (height) {
      elem.style.height = `${height}px`;
    }
  }
}

class FixedWidthResizeBehaviour {
  // Keeps the width fixed at a given value

  constructor(width) {
    this.width = width;
  }

  setInitialSize(elem) {
    elem.style.width = this.width;
  }

  adjustSize(elem, { height }) {
    elem.style.width = this.width;
    if (height) {
      elem.style.height = `${height}px`;
    }
  }
}

class FullWidthResizeBehaviour extends FixedWidthResizeBehaviour {
  // Keeps the element at 100% width

  constructor() {
    super('100%');
  }
}

export {
  IntrinsicSizeResizeBehaviour,
  FixedWidthResizeBehaviour,
  FullWidthResizeBehaviour,
};
